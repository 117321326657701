/**
 * @description: importing all the images and exporting with in ImagesData Object
 * **/

import logo from "../assets/img/logo.svg";
import groupCommunication from "../assets/img//icons/group-communication-icon.svg";
import topicIcon from "../assets/img//icons/topic-icon.svg";
import contentSharing from "../assets/img//icons/content-sharing-icon.svg";
import appStore from "../assets/img/appstore.png";
import playStore from "../assets/img/playstore.png";
import addIcon from "../assets/img/icons/add-icon.svg";
import backIcon from "../assets/img/icons/back-icon.svg";
import backIconWhite from "../assets/img/icons/back-icon-white.svg";
import logoInner from "../assets/img/oddlyou.png";
import profileImage from "../assets/img/user-image.png";
import profileImageMale from "../assets/img/user-image-male.png";
import profileImageFemale from "../assets/img/user-image-female.png";

import downArrowIcon from "../assets/img/icons/share/downArrow.svg";
import downArrowIconWhite from "../assets/img/icons/down-arrow-white.svg";
import upArrowIcon from "../assets/img/icons/share/upArrow.svg";
import upArrowIconWhite from "../assets/img/icons/up-arrow-white.svg";
import connectionIcon from "../assets/img/icons/connection-icon.svg";
import privateIcon from "../assets/img/icons/private-icon-black.svg";
import oneOneIcon from "../assets/img/icons/one-one-icon-black.svg";
import groupIcon from "../assets/img/icons/group-icon-black.svg";
import egowalMemberIcon from "../assets/img/icons/egowal-member-black.svg";
import publicIcon from "../assets/img/icons/public-icon-black.svg";

import connectionIconBlack from "../assets/img/icons/connection-icon-black.svg";
import privateIconBlack from "../assets/img/icons/private-icon-black.svg";
import oneOneIconBlack from "../assets/img/icons/one-one-icon-black.svg";
import groupIconBlack from "../assets/img/icons/group-icon-black.svg";
import egowalMemberIconBlack from "../assets/img/icons/egowal-member-black.svg";
import publicIconBlack from "../assets/img/icons/public-icon-black.svg";

import connectionIconWhite from "../assets/img/icons/connection-icon-white.svg";
import privateIconWhite from "../assets/img/icons/private-icon-black.svg";
import oneOneIconWhite from "../assets/img/icons/one-one-icon-black.svg";
import groupIconWhite from "../assets/img/icons/group-icon-black.svg";

import connectionImage from "../assets/img/connectionUser.png";
import closeIcon from "../assets/img/icons/close-icon.svg";
import postImage from "../assets/img/post-img1.png";
import dashboardIcon from "../assets/img/icons/Dashboard.svg";
import recentShareIcon from "../assets/img/icons/share.svg";
import uploadIcon from "../assets/img/icons/upload.svg";
import connectionNavbarIcon from "../assets/img/icons/connection.svg";
import addTopicIcon from "../assets/img/icons/Addtopic.svg";
import plusIcon from "../assets/img/icons/sidebar/plus-icon.svg";
import groupNavIcon from "../assets/img/icons/group.svg";

import dashboardIconDark from "../assets/img/icons/Dashboard-dark.svg";
import recentShareIconDark from "../assets/img/icons/share-dark.svg";
import uploadIconDarkDark from "../assets/img/icons/upload-dark.svg";
import connectionNavbarIconDark from "../assets/img/icons/connection-dark.svg";
import groupNavIconDark from "../assets/img/icons/group-dark.svg";

import recent from "../assets/img/icons/recent.svg";
import oneOnOneRightblackicon from "../assets/img/icons/oneOnOneRightblackicon.svg";
import connectionRightIconBlack from "../assets/img/icons/connectionRightIconBlack.svg";

import friendIcon from "../assets/img/icons/friendIcon.svg";
import leftArrowBlack from "../assets/img/icons/left-arrow-black.svg";
import leftArrowWhite from "../assets/img/icons/left-arrow-white.svg";

import searchGrey from "../assets/img/icons/search-grey.svg";

import NoTopic from "../assets/img/no-topic.svg";

import NoTopicItems from "../assets/img/no-topic-items.svg";

import NoTopicNotes from "../assets/img/banner/topicDetails//no-topic-notes.svg";

import DeleteIcon from "../assets/img/icons/delete.svg";

import noUpload from "../assets/img/cyfFrame.png";

import FamilyIcon from "../assets/img/icons/FamilyColor.svg";

import FamilyPlainIcon from "../assets/img/icons/FamilyPlain.svg";

import ColleagueIcon from "../assets/img/icons/ColleagueColor.svg";

import ColleaguePlainIcon from "../assets/img/icons/ColleaguePlain.svg";

import FriendPlainIcon from "../assets/img/icons/FriendsPlain.svg";

import FriendIcon from "../assets/img/icons/FriendsColor.svg";

import addIconDark from "../assets/img/icons/ic-add_item.svg";

import filtersIcon from "../assets/img/icons/filters.svg";

import recentShareBetween from "../assets/img/icons/recent-share-between.svg";

import VisitorIcon from "../assets/img/icons/third/ic-eye_on.svg";

import PhotoCountIcon from "../assets/img/icons/third/ic-open-folder-with-document1.svg";

import DashboardAddIcon from "../assets/img/icons/third/ic-add_items2.svg";

import LikeIcon from "../assets/img/icons/Like.svg";

import CommentIcon from "../assets/img/icons/Comment.svg";

import CircleIcon from "../assets/img/icons/Circle.svg";

import ConnectionsIcon from "../assets/img/icons/Connections.svg";

import CalendarIcon from "../assets/img/icons/calendar-icon.svg";

import SendIcon from "../assets/img/icons/fifth/ic-navigation2.svg";

import GroupIconWhite from "../assets/img/icons/group-icon-white.svg";

import HeaderRight from "../assets/img/icons/third/ic-right.svg";

import FooterRight from "../assets/img/icons/third/ic-footer-right.svg";

import CalenderIcon from "../assets/img/icons/second/ic-calendar.svg";

import MultipleIcon from "../assets/img/icons/multiple.svg";

import QuickAddIcon from "../assets/img/icons/quick-add.svg";

import Liked from "../assets/img/icons/topicDetails/likedIcon.svg";

import DropdownIcon from "../assets/img/icons/Dropdown.svg";

import RecentActive from "../assets/img/icons/dashboard/recentActive.svg";

import PrivateActive from "../assets/img/icons/dashboard/privateActive.svg";

import OneOnOneActive from "../assets/img/icons/dashboard/oneOnOneActive.svg";

import PublicActive from "../assets/img/icons/dashboard/publicActive.svg";

import ConnectionActive from "../assets/img/icons/dashboard/connectionActive.svg";

import AllActive from "../assets/img/icons/dashboard/allActive.svg";

import CircleActive from "../assets/img/icons/dashboard/circleActive.svg";

import RecentInactive from "../assets/img/icons/dashboard/recentInactive.svg";

import PrivateInactive from "../assets/img/icons/dashboard/privateInactive.svg";

import OneOnOneInactive from "../assets/img/icons/dashboard/oneOnOneInactive.svg";

import PublicInactive from "../assets/img/icons/dashboard/publicInactive.svg";

import ConnectionInactive from "../assets/img/icons/dashboard/connectionInactive.svg";

import AllInactive from "../assets/img/icons/dashboard/allInactive.svg";

import CircleInactive from "../assets/img/icons/dashboard/circleInactive.svg";

import AllPrimary from "../assets/img/icons/dashboard/allPrimary.svg";

import TaskActive from "../assets/img/icons/dashboard/taskTopicActive.svg";

import TaskInactive from "../assets/img/icons/dashboard/taskTopicInactive.svg";

import AlbumActive from "../assets/img/icons/dashboard/albumTopicActive.svg";

import AlbumInactive from "../assets/img/icons/dashboard/albumTopicInactive.svg";

import ConversationActive from "../assets/img/icons/dashboard/conversationTopicActive.svg";

import ConversationInactive from "../assets/img/icons/dashboard/conversationTopicInactive.svg";

import DocumentActive from "../assets/img/icons/dashboard/docTopicActive.svg";

import DocumentInactive from "../assets/img/icons/dashboard/docTopicInactive.svg";

import Sort from "../assets/img/icons/dashboard/sort.svg";

import DocumentPrimary from "../assets/img/icons/dashboard/documentPrimary.svg";

import AlbumPrimary from "../assets/img/icons/dashboard/albumPrimary.svg";

import TaskPrimary from "../assets/img/icons/dashboard/taskPrimary.svg";

import ConversationPrimary from "../assets/img/icons/dashboard/conversationPrimary.svg";

import Copy from "../assets/img/icons/topicDetails/copy.svg";

import Edit from "../assets/img/icons/topicDetails/edit.svg";

import Delete from "../assets/img/icons/topicDetails/delete.svg";

import Share from "../assets/img/icons/share/share.svg";

import Back from "../assets/img/icons/share/back.svg";

import SideDashboardActive from "../assets/img/icons/sidebar/sideDashboardActive.svg";

import SideUploadActive from "../assets/img/icons/sidebar/sideUploadActive.svg";

import SideShareActive from "../assets/img/icons/sidebar/sideShareActive.svg";

import SideConnectionActive from "../assets/img/icons/sidebar/sideConnectionActive.svg";

import SideCircleActive from "../assets/img/icons/sidebar/sideCircleActive.svg";

import SideDashboardInactive from "../assets/img/icons/sidebar/sideDashboardInactive.svg";

import SideUploadInactive from "../assets/img/icons/sidebar/sideUploadInactive.svg";

import SideShareInactive from "../assets/img/icons/sidebar/sideShareInactive.svg";

import SideConnectionInactive from "../assets/img/icons/sidebar/sideConnectionInactive.svg";

import SideCircleInactive from "../assets/img/icons/sidebar/sideCircleInactive.svg";

import LikeActive from "../assets/img/icons/topicDetails/likeActive.svg";

import CommentActive from "../assets/img/icons/topicDetails/messageActive.svg";

import OptionsActive from "../assets/img/icons/Threedots.svg";

import PlusInactive from "../assets/img/icons/dashboard/plusInactive.svg";

import ReminderActive from "../assets/img/icons/topicDetails/reminderActive.svg";

import TimerActive from "../assets/img/icons/topicDetails/timerActive.svg";

import CalenderInactive from "../assets/img/icons/topicDetails/calendarInactive.svg";

import DeleteInactive from "../assets/img/icons/groups/deleteInactive.svg";

import GroupEditInactive from "../assets/img/icons/groups/groupEditInactive.svg";

import OldSortActive from "../assets/img/icons/dashboard/oldSortActive.svg";

import NewSortActive from "../assets/img/icons/dashboard/newSortActive.svg";

import OldSortInactive from "../assets/img/icons/dashboard/oldSortInactive.svg";

import NewSortInactive from "../assets/img/icons/dashboard/newSortInactive.svg";

import AddCirclePrimary from "../assets/img/icons/dashboard/addCirclesPrimary.svg";

import SearchActive from "../assets/img/icons/dashboard/searchActive.svg";

import AreaExpandInactive from "../assets/img/icons/topicDetails/areaExpandInactive.svg";

import CloseInactive from "../assets/img/icons/dashboard/closeInactive.svg";

import FilterPrimary from "../assets/img/icons/dashboard/filterPrimary.svg";

import FilterInactive from "../assets/img/icons/share/filterInactive.svg";

import CheckPrimary from "../assets/img/icons/profile/checkPrimary.svg";

import OptionsPrimary from "../assets/img/icons/profile/optionsPrimary.svg";

import MediaPrimary from "../assets/img/icons/topicDetails/mediaPrimary.svg";

import ListActive from "../assets/img/icons/dashboard/listTopicActive.svg";

import ListInactive from "../assets/img/icons/dashboard/listTopicInactive.svg";

import ListPrimary from "../assets/img/icons/dashboard/listPrimary.svg";

import FirstTopic from "../assets/img/banner/dashboard/firstTopicBanner.svg";

import UploadProfile from "../assets/img/banner/dashboard/uploadProfileBanner.svg";

import PremiumBanner from "../assets/img/banner/dashboard/premiumBanner.svg";

import NoUserSearch from "../assets/img/banner/search/noUser.svg";

import NoTopicSearch from "../assets/img/banner/search/noTopics.svg";

import NoAlbum from "../assets/img/banner/topicDetails/noAlbum.svg";

import NoUploads from "../assets/img/banner/topicDetails/noUploads.svg";

import NoTask from "../assets/img/banner/topicDetails/noTask.svg";

import NoConvo from "../assets/img/banner/topicDetails/noConvo.svg";

import NotificationIcon from "../assets/img/icons/notificationIcon.svg";

import NotificationIconDefault from "../assets/img/icons/notificationIconDefault.svg";

import AdChoices from "../assets/img/icons/adChoices.svg";

import PurchaseIcon from "../assets/img/icons/profile/purchase.svg";

import VerifiedIcon from "../assets/img/icons/profile/verified.svg";

import AddCirclesActive from "../assets/img/icons/topicDetails/AddCirclesActive.svg";

import Feed from "../assets/img/initial/feed.svg";

import ConnectionAdd from "../assets/img/initial/connectionAdd.svg";

import PrivacyShare from "../assets/img/initial/privacyShare.svg";

import CancelInactive from "../assets/img/icons/dashboard/cancelInactive.svg";

import MailIcon from "../assets/img/icons/mail-icon.svg";

// import PhoneIcon from "../assets/img/icons/phone-icon.svg";

import InviteConnections from "../assets/img/icons/invite-connections.svg";

import PublicIconBlue from "../assets/img/icons/privacy/public-blue.svg";

import PrivateIconBlue from "../assets/img/icons/privacy/private-blue.svg";

import ConnectionsIconBlue from "../assets/img/icons/privacy/connections-blue.svg";

import CircleIconBlue from "../assets/img/icons/privacy/group-blue.svg";

import OneOneIconBlue from "../assets/img/icons/privacy/one-one-blue.svg";

import ChevronIconBlue from "../assets/img/icons/privacy/chevron-blue.svg";

import RightIcon from "../assets/img/icons/ic-right.svg";

import DownIcon from "../assets/img/icons/ic-down.svg";

import UpIcon from "../assets/img/icons/ic-up.svg";

import DownloadActive from "../assets/img/icons/topicDetails/downloadActive.svg";

import DeleteActive from "../assets/img/icons/topicDetails/deleteActive.svg";

import NotificationPrimary from "../assets/img/icons/topicDetails/notificationPrimary.svg";

import GroupAddInactive from "../assets/img/icons/ic-add_Circles.svg";

import EditInactive from "../assets/img/icons/third/ic-edit.svg";

import UserAddInactive from "../assets/img/icons/third/ic-user_plus.svg";

import DownArrowInactive from "../assets/img/icons/down-arrow-icon.svg";

import ListColorIcon from "../assets/img/icons/dashboard/ListColorIcon.svg";

import AlbumColorIcon from "../assets/img/icons/dashboard/AlbumColorIcon.svg";

import TaskListColorIcon from "../assets/img/icons/dashboard/TaskListColorIcon.svg";

import ConversationColorIcon from "../assets/img/icons/dashboard/ConversationColorIcon.svg";

import DocColorIcon from "../assets/img/icons/dashboard/DocColorIcon.svg";

import CommentColor from "../assets/img/icons/CommentColor.svg";

import LikeColor from "../assets/img/icons/LikeColor.svg";

import FilesColor from "../assets/img/icons/FilesColor.svg";

import ViewColor from "../assets/img/icons/ViewColor.svg";

import PublicColor from "../assets/img/icons/PublicColor.svg";

import PrivateColor from "../assets/img/icons/PrivateColor.svg";

import NoTopicsSlim from "../assets/img/icons/noTopicsSlim.svg";

import NoTopicsCreated from "../assets/img/icons/noTopicsCreated.svg";

import AllTabFilterIcon from "../assets/img/icons/dashboard/allFilterIcon.svg";

import AlbumAddIcon from "../assets/img/icons/topicDetails/albumAddIcon.svg";

import NoCommentsIcon from "../assets/img/icons/topicDetails/noComments.svg";

import AllTopicInactive from "../assets/img/icons/dashboard/allTopicInactive.svg";

import AllTopicActive from "../assets/img/icons/dashboard/allTopicActive.svg";

import ClosePrimary from "../assets/img/icons/dashboard/closePrimary.svg";

import DOBIcon from "../assets/img/icons/profile/dobIcon.svg";

import ProfessionIcon from "../assets/img/icons/profile/dobIcon.svg";

import EmailIcon from "../assets/img/icons/profile/emailIcon.svg";

import PhoneIcon from "../assets/img/icons/profile/phoneIcon.svg";

import EditProfileIcon from "../assets/img/icons/profile/editProfileIcon.svg";

import AlbumSelected from "../assets/img/icons/share/albumSelected.svg";

import ListSelected from "../assets/img/icons/share/listSelected.svg";

import ConversationSelected from "../assets/img/icons/share/conversastionSelected.svg";

import TaskSelected from "../assets/img/icons/share/taskSelected.svg";

import DocSelected from "../assets/img/icons/share/docSelected.svg";

import RightArrowSelected from "../assets/img/icons/profile/rightArrowSelected.svg";

import RightArrowInactive from "../assets/img/icons/profile/rightArrowInactive.svg";

import PrivacyBrandIcon from "../assets/img/icons/dashboard/privacyBrandIcon.svg";

import PublicBrandIcon from "../assets/img/icons/dashboard/publicBrandIcon.svg";

import CircleCommonIcon from "../assets/img/icons/dashboard/circlesCommonIcon.svg";

import ImageEditIcon from "../assets/img/icons/profile/imageEditIcon.svg";

import NoConnectionInactive from "../assets/img/icons/share/noConnectionInactive.svg";

import NoConnectionActive from "../assets/img/icons/share/noConnectionActive.svg";

import CloseActive from "../assets/img/icons/dashboard/closeActive.svg";

import ZoomActive from "../assets/img/icons/dashboard/zoomActive.svg";

export const imagesData = {
  dashboardIcon,
  upArrowIcon,
  dashboardIconDark,
  recentShareIcon,
  recentShareIconDark,
  uploadIcon,
  uploadIconDarkDark,
  connectionNavbarIcon,
  connectionNavbarIconDark,
  addTopicIcon,
  postImage,
  logo,
  groupCommunication,
  topicIcon,
  contentSharing,
  appStore,
  playStore,
  addIcon,
  backIcon,
  backIconWhite,
  logoInner,
  profileImage,
  downArrowIcon,
  connectionIcon,
  privateIcon,
  oneOneIcon,
  groupIcon,
  egowalMemberIcon,
  publicIcon,
  connectionIconBlack,
  privateIconBlack,
  oneOneIconBlack,
  groupIconBlack,
  egowalMemberIconBlack,
  publicIconBlack,
  connectionIconWhite,
  privateIconWhite,
  oneOneIconWhite,
  groupIconWhite,
  connectionImage,
  closeIcon,
  groupNavIcon,
  groupNavIconDark,
  recent,
  oneOnOneRightblackicon,
  connectionRightIconBlack,
  friendIcon,
  leftArrowBlack,
  searchGrey,
  NoTopic,
  NoTopicItems,
  DeleteIcon,
  noUpload,
  FamilyIcon,
  ColleagueIcon,
  ColleaguePlainIcon,
  FriendPlainIcon,
  FamilyPlainIcon,
  FriendIcon,
  addIconDark,
  filtersIcon,
  recentShareBetween,
  VisitorIcon,
  PhotoCountIcon,
  DashboardAddIcon,
  LikeIcon,
  CommentIcon,
  CircleIcon,
  ConnectionsIcon,
  CalendarIcon,
  SendIcon,
  GroupIconWhite,
  HeaderRight,
  FooterRight,
  CalenderIcon,
  MultipleIcon,
  QuickAddIcon,
  LikeActive,
  DropdownIcon,
  RecentActive,
  RecentInactive,
  PublicActive,
  PublicInactive,
  PrivateActive,
  PrivateInactive,
  CircleActive,
  CircleInactive,
  ConnectionActive,
  ConnectionInactive,
  AllActive,
  AllInactive,
  OneOnOneActive,
  OneOnOneInactive,
  AllPrimary,
  TaskActive,
  TaskInactive,
  AlbumActive,
  AlbumInactive,
  ConversationActive,
  ConversationInactive,
  DocumentActive,
  DocumentInactive,
  Sort,
  DocumentPrimary,
  TaskPrimary,
  ConversationPrimary,
  AlbumPrimary,
  Copy,
  Delete,
  Edit,
  Share,
  Back,
  SideCircleActive,
  SideCircleInactive,
  SideConnectionActive,
  SideConnectionInactive,
  SideDashboardActive,
  SideDashboardInactive,
  SideUploadActive,
  SideUploadInactive,
  SideShareActive,
  SideShareInactive,
  Liked,
  CommentActive,
  OptionsActive,
  PlusInactive,
  ReminderActive,
  TimerActive,
  CalenderInactive,
  DeleteInactive,
  GroupEditInactive,
  NoTopicNotes,
  OldSortActive,
  OldSortInactive,
  NewSortActive,
  NewSortInactive,
  AddCirclePrimary,
  SearchActive,
  AreaExpandInactive,
  CloseInactive,
  FilterPrimary,
  FilterInactive,
  CheckPrimary,
  OptionsPrimary,
  MediaPrimary,
  ListActive,
  ListInactive,
  ListPrimary,
  FirstTopic,
  UploadProfile,
  PremiumBanner,
  NoUserSearch,
  NoTopicSearch,
  NoAlbum,
  NoTask,
  NoConvo,
  NoUploads,
  leftArrowWhite,
  profileImageFemale,
  profileImageMale,
  NotificationIcon,
  AdChoices,
  PurchaseIcon,
  AddCirclesActive,
  Feed,
  ConnectionAdd,
  PrivacyShare,
  VerifiedIcon,
  CancelInactive,
  MailIcon,
  PhoneIcon,
  InviteConnections,
  PublicIconBlue,
  PrivateIconBlue,
  ChevronIconBlue,
  ConnectionsIconBlue,
  CircleIconBlue,
  OneOneIconBlue,
  RightIcon,
  DownIcon,
  downArrowIconWhite,
  upArrowIconWhite,
  UpIcon,
  plusIcon,
  DownloadActive,
  DeleteActive,
  NotificationPrimary,
  GroupAddInactive,
  EditInactive,
  UserAddInactive,
  DownArrowInactive,
  ListColorIcon,
  AlbumColorIcon,
  TaskListColorIcon,
  ConversationColorIcon,
  DocColorIcon,
  CommentColor,
  LikeColor,
  FilesColor,
  ViewColor,
  PublicColor,
  PrivateColor,
  NoTopicsSlim,
  AllTabFilterIcon,
  AlbumAddIcon,
  NoTopicsCreated,
  NoCommentsIcon,
  AllTopicActive,
  AllTopicInactive,
  ClosePrimary,
  NotificationIconDefault,
  EmailIcon,
  DOBIcon,
  ProfessionIcon,
  EditProfileIcon,
  AlbumSelected,
  ListSelected,
  TaskSelected,
  DocSelected,
  ConversationSelected,
  RightArrowSelected,
  RightArrowInactive,
  PrivacyBrandIcon,
  PublicBrandIcon,
  CircleCommonIcon,
  ImageEditIcon,
  NoConnectionActive,
  NoConnectionInactive,
  CloseActive,
  ZoomActive,
};
