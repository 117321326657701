import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";
import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  FormControl,
  Form,
  Button,
  Image,
} from "react-bootstrap";
import { useAppContext } from "../../libs/context-libs";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import ApiService from "../../services/api.service";
import DefaultProfile from "../../assets/img/default-profile.png";
import { useSelector, useDispatch } from "react-redux";
import { clearUserState } from "../../redux/user/userInfo";
//import { clearTopicsState } from "../../redux/topics/topic";
import config from "../../config";
import { imagesData } from "../../constants/images";
import Moment from "react-moment";

const {
  logoInner,
  searchGrey,
  NotificationIcon,
  NotificationIconDefault,
  DownArrowInactive,
} = imagesData;

const Header = ({ isIdle }) => {
  const [title, setTitle] = useState("Oddlyou");
  const [description, setDescription] = useState(
    "Sign up to see trending content around the world. Create and explore the latest topics and media. Share photos and videos with friends."
  );
  const { setIsAuthenticated } = useAppContext();
  const history = useHistory();
  const { auth } = useSelector((state) => state.user);
  const { userInfo } = useSelector((state) => state.user);
  const [userOnline, setUserOnline] = useState(isIdle);
  const [profilePicture, setProfilePicture] = useState(null);
  const [ewUserName, setEwUserName] = useState(null);
  const [ewNotifications, setEwNotifications] = useState(null);

  let userName;
  userName = localStorage.getItem("ewUserName");
  let ewUserID;
  ewUserID = localStorage.getItem("ewUserID");
  let profilePic;
  profilePic = localStorage.getItem("ewProfilePic");
  let notifications;
  notifications = localStorage.getItem("ewNotifications")
    ? JSON.parse(localStorage.getItem("ewNotifications"))
    : [];
  let userInitials;
  userInitials =
    userName && userName !== "NA"
      ? userName
          .match(/(\b\S)?/g)
          .join("")
          .match(/(^\S|\S$)?/g)
          .join("")
          .toUpperCase()
      : "NA";

  useEffect(() => {
    setEwUserName(localStorage.getItem("ewUserName") || null);
    setProfilePicture(localStorage.getItem("ewProfilePic") || null);
    setEwNotifications(localStorage.getItem("ewNotifications") || null);
    window.addEventListener("storage", storageEventHandler, false);
  }, []);

  function storageEventHandler() {
    setEwNotifications(localStorage.getItem("ewNotifications") || null);
    setEwUserName(localStorage.getItem("ewUserName") || null);
    setProfilePicture(localStorage.getItem("ewProfilePic") || null);
    localStorage.getItem("userName");
  }

  function localStorageFunc() {
    storageEventHandler();
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (userOnline) setUserOnline(true);
    else setUserOnline(false);
  }, [userOnline]);

  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
  }, [title, description]);

  const handleLogOut = async () => {
    // Clearing redux state
    dispatch(clearUserState());
    //dispatch(clearTopicsState());

    // Clearing local EW storage
    localStorage.setItem("ewUserID", "");
    localStorage.setItem("ewBasicInfo", "");
    localStorage.setItem("ewProfilePic", "");
    localStorage.setItem("ewUserName", "");
    localStorage.setItem("isOnline", "");
    //localStorage.setItem("ewNotifications", "");
    localStorage.setItem("ewFirstSignup", "");
    localStorage.setItem("ewEvent", "");
    localStorage.setItem("ewUsersOnline", "");

    // Clear Cognito session
    await Auth.signOut();
    // Clear EW cookies
    await ApiService.userOnlineStatus({ status: 0 });
    await ApiService.logout();
    // Redirect to login page
    history.push("/login");
    setIsAuthenticated(false);
  };

  const clearAllNotifications = () => {
    localStorage.setItem("ewNotifications", []);
    document.getElementById("hiddenBtn").click();
    //setNotifications([]);
  };

  const handleNotificationType = (data) => {
    const eventType = data?.eventType;
    if (eventType === "User_login") {
      return "logged in";
    }
    if (eventType === "topic_view") {
      return `viewed your topic ${data.objectName}`;
    }
    if (eventType === "Note_Thumbs_Up") {
      return `liked you note ${data.objectName}`;
    }
    if (eventType === "Note_Thumbs_Down") {
      return `unliked your note ${data.objectName}`;
    }
    if (eventType === "Note_comments") {
      return `commented on note ${JSON.parse(data.objectName).text.replace(
        /(<([^>]+)>)/gi,
        ""
      )}`;
    }
    if (eventType === "topic_photo_added") {
      return `added photo`;
    }
    if (eventType === "topic_note_added") {
      return `added note ${JSON.parse(data?.variableData)?.text?.replace(
        /(<([^>]+)>)/gi,
        ""
      )}`;
    }
  };

  const handleRouting = (data) => {
    const eventType = data?.eventType;
    if (eventType === "topic_view") {
      return `/topic/${data.objectID}`;
    }
    if (eventType === "Note_Thumbs_Up") {
      return `/topic/${data.secondID}`;
    }
    if (eventType === "Note_Thumbs_Down") {
      return `/topic/${data.secondID}`;
    }
    if (eventType === "Note_comments") {
      return `/topic/${data.secondID}`;
    }
  };

  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>{title ? title : "Oddlyou ™"}</title>
        <link rel="canonical" href="https://egowall.com" />
        <meta
          name="description"
          content={description ? description : "Be the real you ™"}
        />
      </Helmet>
      <header>
        <div className="header-container">
          <button
            style={{ display: "none" }}
            onClick={localStorageFunc}
            id="hiddenBtn"
          >
            Hidden Button
          </button>
          <Navbar bg="transparent" expand="lg">
            <Container className="full-width">
              <div className="row-flex">
                <Navbar.Brand as={Link} to="/dashboard">
                  <img height="40" src={logoInner} alt="Oddly You" />
                </Navbar.Brand>
                {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                <Navbar.Collapse id="basic-navbar-nav" className="ms-5">
                  <div className="form-group">
                    <Form
                      action="/search"
                      className="form-group header-search"
                      inline
                    >
                      <div className="input-group w-100">
                        <FormControl
                          name="q"
                          type="text"
                          placeholder="Search here"
                          className="mr-lg-2 fz-13 text-light-purple"
                        />

                        <div className="input-group-append">
                          <button
                            className="btn btn-primary search-button"
                            type="submit"
                          >
                            <img src={searchGrey} />
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </Navbar.Collapse>
              </div>

              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                  <div className="profile-icon">
                    <figcaption>
                      <NavDropdown
                        title={
                          <div className="notification-icon-wrp">
                            {(notifications?.length > 0 && (
                              <img height="17" src={NotificationIcon} />
                            )) || (
                              <img height="17" src={NotificationIconDefault} />
                            )}
                            {/* <div className="notification-count">
                              {notifications?.length > 0
                                ? notifications?.length > 99
                                  ? "99+"
                                  : notifications?.length
                                : "0"}
                            </div> */}
                          </div>
                        }
                        id="basic-nav-dropdown"
                        className="notification me-3"
                      >
                        <Navbar.Brand className="justify-content-between row-flex">
                          <Navbar.Text className="justify-content-start">
                            Notifications ({notifications.length})
                          </Navbar.Text>
                          <Navbar.Text
                            onClick={clearAllNotifications}
                            className="justify-content-end"
                            style={{ cursor: "pointer" }}
                          >
                            Clear All
                          </Navbar.Text>
                        </Navbar.Brand>

                        {notifications?.length === 0 ? (
                          <Navbar.Text className="no-notification">
                            No notifications yet
                          </Navbar.Text>
                        ) : (
                          notifications
                            ?.reverse()
                            .splice(
                              0,
                              notifications.length >= 4
                                ? 4
                                : notifications.length
                            )
                            ?.map((notification) => (
                              <NavDropdown.Item
                                as={Link}
                                to={handleRouting(notification?.eventData)}
                                style={{ cursor: "pointer" }}
                              >
                                <div className="row-flex flex-fill">
                                  <div className="notification-thumbnail flex-shrink-0">
                                    <img
                                      src={
                                        notification?.eventData
                                          ?.profilePicThumbURL
                                      }
                                    />
                                  </div>
                                  <div className="row-flex flex-column flex-grow-1 ms-2 text-wrap text-height-1">
                                    <label
                                      style={{ lineHeight: 1 }}
                                      className="fz-14"
                                    >
                                      {`${
                                        notification?.eventData
                                          ?.sourceUserFullName
                                      } ${handleNotificationType(
                                        notification?.eventData
                                      )}`}
                                    </label>
                                    <label className="fz-12 text-gray-dbd">
                                      <Moment
                                        fromNow={
                                          notification?.eventData?.eventDateTime
                                        }
                                      />
                                    </label>
                                  </div>
                                </div>
                              </NavDropdown.Item>
                            ))
                        )}
                        <NavDropdown.Item
                          as={Link}
                          to="/notification"
                          className="all-notification"
                        >
                          View all
                        </NavDropdown.Item>
                      </NavDropdown>
                    </figcaption>
                    <figure>
                      <Link
                        className="profile-xs online-icon-container me-2"
                        to={"/profile/" + ewUserID}
                      >
                        {profilePic === "undefined" || !profilePic ? (
                          <span className="avatar-bg-primary text-white rounded-circle fz-12">
                            {userInitials}
                          </span>
                        ) : (
                          <Image
                            className="profile-img"
                            src={profilePic}
                            roundedCircle
                          />
                        )}
                        {/* {profilePic && profilePic !== "undefined" && (

                        )} */}
                        <div
                          className={`online-status-circle ${
                            !isIdle ? "online-status-circle-on" : ""
                          }`}
                        ></div>
                      </Link>
                      <figcaption>
                        <NavDropdown
                          title={
                            <div>
                              {userName} <img src={DownArrowInactive} />
                            </div>
                          }
                          id="basic-nav-dropdown"
                          className="nameDropDown me-3"
                        >
                          <NavDropdown.Item as={Link} to="/notification">
                            Notifications
                          </NavDropdown.Item>

                          <NavDropdown.Item onClick={handleLogOut}>
                            Logout
                          </NavDropdown.Item>
                        </NavDropdown>
                      </figcaption>
                    </figure>
                  </div>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </header>
    </>
  );
};

export default withRouter(Header);
