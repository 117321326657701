/* eslint-disable */

const config = {
  dev: {
    aws_project_region: "us-east-1",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_W15y3Zz82",
    aws_user_pools_web_client_id: "2apistu9c2l625r2np66osn2p6",
  },
  testing: {
    aws_project_region: "us-east-1",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_W15y3Zz82",
    aws_user_pools_web_client_id: "2apistu9c2l625r2np66osn2p6",
  },
  staging: {
    aws_project_region: "us-east-1",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_zMF9zgkvq",
    aws_user_pools_web_client_id: "657nu0ke1iblo5pqusba7ak013",
  },
  prod: {
    aws_project_region: "us-east-1",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_R10BSbADU",
    aws_user_pools_web_client_id: "3tsle3mhgj42np98ttf1nj0bau",
  },
};

export default config;
