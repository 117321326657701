import { imagesData } from "./images";
//import { CognitoUserPool } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import ApiService from "../services/api.service";

const {
  postImage,
  TaskActive,
  TaskInactive,
  DocumentActive,
  DocumentInactive,
  ConversationActive,
  ConversationInactive,
  AlbumActive,
  AlbumInactive,
  AllActive,
  AllInactive,
  SideCircleActive,
  SideConnectionActive,
  SideCircleInactive,
  SideConnectionInactive,
  SideDashboardActive,
  SideDashboardInactive,
  SideUploadActive,
  SideUploadInactive,
  SideShareActive,
  SideShareInactive,
  PublicActive,
  PrivateActive,
  OneOnOneActive,
  ConnectionActive,
  CircleActive,
  PublicInactive,
  PrivateInactive,
  OneOnOneInactive,
  ConnectionInactive,
  CircleInactive,
  OldSortActive,
  OldSortInactive,
  NewSortActive,
  NewSortInactive,
  FilterInactive,
  ListActive,
  ListInactive,
  AllPrimary,
  ListColorIcon,
  DocColorIcon,
  AlbumColorIcon,
  ConversationColorIcon,
  TaskListColorIcon,
  AllTopicActive,
  AllTopicInactive,
  plusIcon,
  AlbumSelected,
  TaskSelected,
  ConversationSelected,
  ListSelected,
  DocSelected,
} = imagesData;

export const PrivacyData = [
  {
    title: "Private",
    subTitle: "Keep this one to yourself",
    id: 3,
    image: PrivateActive,
    icon: PrivateInactive,
  },
  {
    title: "One on One",
    subTitle: "Share it only with one person, forever.",
    id: 6,
    image: OneOnOneActive,
    icon: OneOnOneInactive,
  },
  {
    title: "Connections",
    subTitle: "Choose a few or many of your connections",
    id: 4,
    image: ConnectionActive,
    icon: ConnectionInactive,
  },
  {
    title: "Circles",
    subTitle: "Narrow this content to a specific Circle.",
    id: 5,
    image: CircleActive,
    icon: CircleInactive,
  },
  // {
  //   title: "All Egowall Members",
  //   subTitle: "Anyone on or off Egowall",
  //   id: 2,
  //   image: egowalMemberIconBlack,
  // },
  {
    title: "Public",
    subTitle: "All Oddly users and guests.",
    id: 1,
    image: PublicActive,
    icon: PublicInactive,
  },
];

export const ProfilePrivacyData = [
  // {
  //   title: "Public",
  //   subTitle: "All Egowall users and guests.",
  //   id: 2,
  //   image: PublicActive,
  //   icon: PublicInactive,
  // },
  {
    title: "Private",
    subTitle: "Keep this one to yourself.",
    id: 3,
    image: PrivateActive,
    icon: PrivateInactive,
  },
  {
    title: "Connections",
    subTitle: "Choose a few or many of your connections",
    id: 4,
    image: OneOnOneActive,
    icon: OneOnOneInactive,
  },
  {
    title: "Groups",
    subTitle: "Narrow to a specific circle or group",
    id: 5,
    image: CircleActive,
    icon: CircleInactive,
  },
];

export const postData = [
  {
    text:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.  ",
    postImg: postImage,
  },
  {
    postImg: postImage,
  },
  {
    postImg: postImage,
    postImg: postImage,
    postImg: postImage,
    postImg: postImage,
    postImg: postImage,
  },
];

export const TopicDetailspostData = [
  {
    id: 1,
    text:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.  ",
  },
  {
    id: 2,
    postImg: postImage,
  },
];

export const dashLeftPanelData = [
  {
    name: "Home",
    id: 1,
    iconDark: SideDashboardActive,
    icon: SideDashboardActive,
    pathname: "/dashboard",
  },
  {
    name: "Share",
    id: 2,
    iconDark: SideShareActive,
    icon: SideShareActive,
    pathname: "/recent-share",
  },
  {
    name: "Uploads",
    id: 3,
    iconDark: SideUploadActive,
    icon: SideUploadActive,
    pathname: "/uploads",
  },
  {
    name: "Connections",
    id: 4,
    iconDark: SideConnectionActive,
    icon: SideConnectionActive,
    pathname: "/connections",
  },
  {
    name: "Create",
    id: 5,
    iconDark: plusIcon,
    icon: plusIcon,
    pathname: "/create",
  },
];

const userPoolData = {
  UserPoolId: "us-east-1_W15y3Zz82",
  ClientId: "2apistu9c2l625r2np66osn2p6",
};

//export const userPool = new CognitoUserPool(userPoolData);
export const userPool = "";

export const createCongnitoAttrItem = (keyName, value) => {
  return {
    Name: keyName,
    Value: value,
  };
};

export const filterOptions = [
  {
    name: "All",
    value: 0,
    activeIcon: AllTopicActive,
    inactiveIcon: AllTopicInactive,
    class: "fz-13 tag-blue-3",
  },
  {
    name: "List",
    value: 1,
    activeIcon: ListActive,
    inactiveIcon: ListInactive,
    class: "fz-13 tag-blue-1",
  },
  {
    name: "Album",
    value: 2,
    activeIcon: AlbumActive,
    inactiveIcon: AlbumInactive,
    class: "fz-13 tag-dark-green",
  },
  {
    name: "Task List",
    value: 3,
    activeIcon: TaskActive,
    inactiveIcon: TaskInactive,
    class: "fz-13 tag-dark-purple",
  },
  {
    name: "Conversation",
    value: 4,
    activeIcon: ConversationActive,
    inactiveIcon: ConversationInactive,
    class: "fz-13 tag-pink",
  },
  {
    name: "Document",
    value: 5,
    activeIcon: DocumentActive,
    inactiveIcon: DocumentInactive,
    class: "fz-13 tag-blue-2",
  },
];

export const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];

export const fileTypeValid = (file, fileTypes) => {
  return fileTypes.some((fileType) => fileType === file.type);
};

export const sortOptions = [
  {
    name: "Sort Newest to Oldest",
    value: 0,
    activeIcon: NewSortActive,
    inactiveIcon: NewSortInactive,
  },
  {
    name: "Sort Oldest to Newest",
    value: 1,
    activeIcon: OldSortActive,
    inactiveIcon: OldSortInactive,
  },
];

export const sortProfileOptions = [
  {
    name: "One on One",
    value: 0,
    activeIcon: OneOnOneActive,
    inactiveIcon: OneOnOneInactive,
  },
  {
    name: "One to Many",
    value: 1,
    activeIcon: ConnectionActive,
    inactiveIcon: ConnectionInactive,
  },
  {
    name: "Both",
    value: 2,
    activeIcon: FilterInactive,
    inactiveIcon: FilterInactive,
  },
];

export const truncateText = (str, max, len) => {
  return str?.length > max ? str?.substring(0, len) + "..." : str;
};

export const truncateNoteText = (str, max, len) => {
  if (str?.props && str?.props?.children?.length > 0)
    return str.props.children.length > max
      ? str.props.children.substring(0, len) + "..."
      : str.props.children;
  else return str;
};

export const addEntryLocalStorage = (event) => {
  // Parse any JSON previously stored in allEntries
  let existingEntries = localStorage.getItem("ewNotifications");
  existingEntries = existingEntries
    ? JSON.parse(localStorage.getItem("ewNotifications"))
    : [];
  if (existingEntries === null) existingEntries = [];
  var entry = event;
  localStorage.setItem("ewEvent", JSON.stringify(entry));
  // Save allEntries back to local storage
  existingEntries.push(entry);
  localStorage.setItem("ewNotifications", JSON.stringify(existingEntries));
};

export const addOnlineLocalStorage = (event) => {
  // Parse any JSON previously stored in allEntries
  let existingEntries = localStorage.getItem("ewUsersOnline");
  existingEntries = existingEntries
    ? JSON.parse(localStorage.getItem("ewUsersOnline"))
    : [];
  if (existingEntries === null) existingEntries = [];
  var entry = event;

  // Save allEntries back to local storage
  if (event?.eventData?.eventType === "User_online") {
    if (existingEntries.some((user) => user === event?.eventData?.userID)) {
      return;
    } else {
      existingEntries.push(entry?.eventData?.userID);
    }
  }
  if (event?.eventData?.eventType === "User_offline") {
    if (existingEntries.some((user) => user === event?.eventData?.userID)) {
      var index = existingEntries.indexOf(event?.eventData?.userID); // get index if value exists otherwise -1
      if (index > -1) {
        // if exists remove as user is offline
        existingEntries.splice(index, 1);
      }
    } else {
      return;
    }
  }
  localStorage.setItem("ewUsersOnline", JSON.stringify(existingEntries));
};

export const clearLocalStorage = (key) => {
  for (key in localStorage) {
    delete localStorage[key];
  }
};

export const getUserInitials = (userName) => {
  return userName
    .match(/(\b\S)?/g)
    .join("")
    .match(/(^\S|\S$)?/g)
    .join("")
    .toUpperCase();
};

export const handleNotificationCount = (notifications, id) => {
  let notificationFilter = notifications.filter(
    (o1) =>
      o1.eventData.sourceUserID === id &&
      (o1.eventData.eventType === "topic_note_added" ||
        o1.eventData.eventType === "Note_comments" ||
        o1.eventData.eventType === "topic_view" ||
        o1.eventData.eventType === "topic_photo_added")
  );
  return notificationFilter.length;
};

export const handleNotificationType = (data) => {
  const eventType = data?.eventType;
  if (eventType === "User_login") {
    return "logged in";
  }
  if (eventType === "topic_view") {
    return `viewed your topic ${data.objectName}`;
  }
  if (eventType === "Note_Thumbs_Up") {
    return `liked you note ${data.objectName}`;
  }
  if (eventType === "Note_Thumbs_Down") {
    return `unliked your note ${data.objectName}`;
  }
  if (eventType === "Note_comments") {
    return `added comment `;
  }
  if (eventType === "topic_note_added") {
    return `added note ${truncateText(
      JSON.parse(data?.variableData)?.text?.replace(/(<([^>]+)>)/gi, ""),
      25,
      20
    )}`;
  }
};

export const handleNotificationRouting = (data) => {
  const eventType = data?.eventType;
  if (eventType === "topic_view") {
    return `/topic/${data.objectID}`;
  }
  if (eventType === "Note_Thumbs_Up") {
    return `/topic/${data.secondID}`;
  }
  if (eventType === "Note_Thumbs_Down") {
    return `/topic/${data.secondID}`;
  }
  if (eventType === "Note_comments") {
    return `/topic/${data.secondID}`;
  }
};

export const groupedNotificationItems = (data) => {
  return data.reduce((acc, item) => {
    const key = item.eventData?.secondID || item.eventData?.objectID;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});
};

export const groupReactionEvents = (data) => {
  var groups = {};

  data.forEach(function (item) {
    var key = item.userID + "-" + item.eventType;
    if (!groups[key]) {
      groups[key] = {
        eventType: item.eventType,
        userID: item.userID,
        count: 0,
        items: [],
      };
    }
    groups[key].count++;
    groups[key].items.push(item);
  });

  var groupedData = Object.values(groups);
  return groupedData;
};

export const filteredReactionEvents = (data) => {
  let filteredArray = data.filter(
    (event, index, self) =>
      index ===
      self.findIndex(
        (e) =>
          e.sourceUserID === event.sourceUserID &&
          e.eventType === event.eventType
      )
  );

  return filteredArray;
};

export const groupedByEventType = (data) => {
  let groupedByEventType = data.reduce((acc, cur) => {
    if (!acc[cur.eventType]) {
      acc[cur.eventType] = [];
    }
    acc[cur.eventType].push(cur);
    return acc;
  }, {});
};

export const handleLogOut = async () => {
  // Clearing local EW storage
  localStorage.clear();
  // Clear Cognito session
  await Auth.signOut();
  // Clear EW cookies
  await ApiService.logout();
};

export const tabTitle = [
  {
    title: "All",
    page: 1,
    tab: "0",
    activeIcon: AllActive,
    inactiveIcon: AllInactive,
    class: "fz-13 tag-blue-3",
  },
  {
    title: "Public",
    page: 1,
    tab: "1",
    activeIcon: PublicActive,
    inactiveIcon: PublicInactive,
    class: "fz-13 tag-red-1",
  },
  {
    title: "Private",
    page: 1,
    tab: "3",
    activeIcon: PrivateActive,
    inactiveIcon: PrivateInactive,
    class: "fz-13 tag-blue-3",
  },
  {
    title: "Connections",
    page: 1,
    tab: "4",
    activeIcon: ConnectionActive,
    inactiveIcon: ConnectionInactive,
    class: "fz-13 tag-light-purple",
  },

  {
    title: "Circles",
    page: 1,
    tab: "5",
    activeIcon: CircleActive,
    inactiveIcon: CircleInactive,
    class: "fz-13 tag-dark-yellow",
  },
  {
    title: "One on One",
    page: 1,
    tab: "6",
    activeIcon: OneOnOneActive,
    inactiveIcon: OneOnOneInactive,
    class: "fz-13 tag-light-green",
  },
];

export const TopicOptions = [
  {
    name: "Album",
    subtext: "Create a gallery of Photos",
    id: "2",
    activeIcon: AlbumSelected,
    inactiveIcon: AlbumInactive,
    activeClass:
      "row-flex align-items-center px-3 py-2 br-5 mb-3 bg-random-orange",
    inActiveClass: "row-flex align-items-center px-3 py-2 br-5 mb-3",
  },
  {
    name: "Conversation",
    subtext: "Create a chat thread",
    id: "4",
    activeIcon: ConversationSelected,
    inactiveIcon: ConversationInactive,
    activeClass:
      "row-flex align-items-center px-3 py-2 br-5 mb-3 bg-random-orange",
    inActiveClass: "row-flex align-items-center px-3 py-2 br-5 mb-3",
  },

  {
    name: "Document",
    subtext: "Create a Document of images and notes",
    id: "5",
    activeIcon: DocSelected,
    inactiveIcon: DocumentInactive,
    activeClass:
      "row-flex align-items-center px-3 py-2 br-5 mb-3 bg-random-orange",
    inActiveClass: "row-flex align-items-center px-3 py-2 br-5 mb-3",
  },
  {
    name: "List",
    subtext: "Add notes and images and collect your thoughts",
    id: "1",
    activeIcon: ListSelected,
    inactiveIcon: ListInactive,
    activeClass:
      "row-flex align-items-center px-3 py-2 br-5 mb-3 bg-random-orange",
    inActiveClass: "row-flex align-items-center px-3 py-2 br-5 mb-3",
  },

  {
    name: "Task List",
    subtext: "Create a list of items you plan to do",
    id: "3",
    activeIcon: TaskSelected,
    inactiveIcon: TaskInactive,
    activeClass:
      "row-flex align-items-center px-3 py-2 br-5 mb-3 bg-random-orange bg-random-orange",
    inActiveClass: "row-flex align-items-center px-3 py-2 br-5 mb-3",
  },
];
