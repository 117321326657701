import React, { useState, useEffect, useCallback } from "react";
import config from "./config";
import Header from "./components/Header";
import { AppContext } from "./libs/context-libs";
import { Auth } from "aws-amplify";
import ApiService from "./services/api.service";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes/Routes";
import { useIdleTimer } from "react-idle-timer";
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from "react-toastify";
import {
  addEntryLocalStorage,
  addOnlineLocalStorage,
} from "./constants/common";
import "react-toastify/dist/ReactToastify.css";
import {
  getNotifications,
  getNotificationsSuccess,
  getNotificationsFailure,
  setNotifications,
  addNotification,
  deleteNotification,
  deleteAllNotifications,
} from "./redux/notifications/notification";
import { useDispatch, useSelector } from "react-redux";

const devStage = process.env.REACT_APP_DEV_ENV;
const domain = process.env.REACT_APP_WS_DOMAIN;
const stage = process.env.REACT_APP_WS_STAGE;
const time = process.env.REACT_APP_SOCKET_RECONNECTION_TIMEOUT;
const socketUrl = `wss://${domain}/${stage}`;

function App() {
  const history = createBrowserHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [socketConnection, setSocketConnection] = useState(null);
  const [isIdle, setIsIdle] = useState(false);

  const dispatch = useDispatch();

  const { notifications } = useSelector((state) => state.notifications);

  console.log("notification selector =>>>>>>>", notifications);

  let basicInfo = localStorage.getItem("ewBasicInfo")
    ? JSON.parse(localStorage.getItem("ewBasicInfo"))
    : "";
  let ewUserID = localStorage.getItem("ewUserID");
  let userEmail = localStorage.getItem("ewBasicInfo")
    ? JSON.parse(localStorage.getItem("ewBasicInfo"))
    : "";
  userEmail = userEmail?.users?.userEmail;

  useEffect(() => {
    onLoad();
    window.process = {
      ...window.process,
    };
  }, []);

  const onIdle = () => {
    localStorage.setItem("isOnline", false);
    setIsIdle(true);
  };

  const onActive = () => {
    localStorage.setItem("isOnline", true);
    ApiService.userOnlineStatus({ status: 1 });
    setIsIdle(false);
    startSocket();
  };

  useIdleTimer({ timeout: 1000 * 500, onIdle, onActive });

  const handleOnIdle = (event) => {
    console.log("user is idle", event);
    console.log("last active", getLastActiveTime());
    ApiService.userOnlineStatus({ status: 0 });
    localStorage.setItem("ewUsersOnline", "");
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const startSocket = useCallback(() => {
    const socket = new WebSocket(socketUrl);

    socket.onopen = function () {
      socket.send(
        JSON.stringify({
          action: "sendMessage",
          userName: userEmail,
        })
      );
    };

    socket.onmessage = function (event) {
      if (
        event.data === "Hi--No events to send" ||
        event.data === "Hi--Events queued" ||
        event.data === "Hi--invalid input data" ||
        JSON.parse(event.data).message === "Internal server error"
      ) {
        return;
      } else {
        let emoji = "🦄";
        const data = JSON.parse(event.data);
        if (ewUserID === data?.eventData?.sourceUserID) return;
        if (
          data?.eventData?.eventType === "topic_note_added" ||
          data?.eventData?.eventType === "Note_comments" ||
          data?.eventData?.eventType === "topic_view" ||
          data?.eventData?.eventType === "topic_photo_added"
        ) {
          dispatch(addNotification(data));
          addEntryLocalStorage(data, false);
        }

        if (data?.eventData?.eventType === "Note_Thumbs_Up") {
          {
            dispatch(addNotification);
            emoji = "👍";
          }
        } else if (data?.eventData?.eventType === "Note_Thumbs_Down") {
          emoji = "👎";
        } else if (
          data?.eventData?.eventType === "User_online" ||
          data?.eventData?.eventType === "User_offline"
        ) {
          addOnlineLocalStorage(data);
          return;
        }
        toast(
          `${emoji} ${data?.eventData?.sourceUserFullName} ${data?.eventData?.eventType}`
        );
        document.getElementById("hiddenBtn").click();
      }
    };

    socket.onclose = function (event) {
      console.log("Socket closed!");
    };

    socket.onerror = function (error) {
      toast.info(`${error} message`);
      //  alert(`[error] ${error.message}`);
    };
    setSocketConnection(socket);
  }, [setSocketConnection]);

  useEffect(() => {
    startSocket();
  }, [startSocket]);

  const onLoad = async () => {
    try {
      const result = await Auth.currentAuthenticatedUser();
      if (result && (result.username || result.email)) {
        setIsAuthenticated(true);
      }
    } catch (error) {
      console.log(error);
      //history.push("/login");
    }

    setIsLoading(false);
  };

  return (
    <Router history={history}>
      <AppContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
        {/*(devStage === "dev" ||
          devStage === "testing" ||
          devStage === "staging") && (
          <div
            className={`env-banner ${
              devStage === "testing"
                ? "env-banner-testing"
                : devStage === "staging"
                ? "env-banner-staging"
                : ""
            }`}
          >
            {(devStage === "dev" && "development") || devStage}
          </div>
          )*/}
        <div className="app d-flex flex-column flex-root">
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {isAuthenticated && <Header isIdle={isIdle} />}
          <Routes />
        </div>
      </AppContext.Provider>
    </Router>
  );
}

export default App;
