import { toast } from "react-toastify";
import { createBrowserHistory } from "history";
import interceptor from "../constants/http-common";
const queryString = require("query-string");

const apiService = {};
const history = createBrowserHistory();

apiService.loginCognito = async function (data) {
  console.log("Login Cognito");
  const response = await interceptor.post(
    "ajax/logincognito",
    new URLSearchParams({
      token: data,
      format: "json",
    })
  );
  console.log("User data EW api:", response);
  return response;
};

apiService.registerCognito = async function (data) {
  console.log("Register Cognito ", data);
  const response = await interceptor.post(
    "ajax/registercognito",
    new URLSearchParams({
      userEmail: data.userEmail,
      token: data.token,
      "dob[day]": data.day,
      "dob[month]": data.month,
      "dob[year]": data.year,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      format: "json",
    })
  );
  console.log("User data EW api:", response);
  return response;
};

apiService.getBasicInfo = async function (data) {
  console.log("Get user basic info");
  const response = await interceptor.post(
    "ajax/users",
    new URLSearchParams({
      requestType: "basicInfoLoad",
      format: "json",
      userID: data,
    })
  );
  console.log("User basic info data:", response);
  return response;
};

apiService.getTopics = async function (data) {
  console.log("Get user topics", data);
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicList",
      pageNo: data.page,
      searchType: data.searchType,
      topicType: data.topicType ? data.topicType : 0,
      ownerUserID: data.ownerUserID,
      interaction: data.interaction ? data.interaction : 0,
      list: "mini",
      format: "json",
    })
  );
  console.log("Topics data:", response);
  return response;
};

apiService.getTimeline = async function (data) {
  console.log("Get user timeline");
  const response = await interceptor.post(
    "ajax/users",
    new URLSearchParams({
      requestType: "timeline",
      pageNo: data.page,
      timeline: "topic",
      topicType: data.topicType ? data.topicType : 0,
      userID: data.userID,
      format: "json",
    })
  );
  console.log("Timeline data:", response);
  return response;
};

apiService.getTopicListMini = async function (data) {
  console.log("Get topicList mini");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicList",
      pageNo: data.page,
      list: "mini",
      topicType: data.topicType ? data.topicType : 0,
      searchType: data.searchType,
      interaction: data.interaction ? data.interaction : 0,
      format: "json",
    })
  );
  console.log("Topic list mini data:", response);
  return response;
};

apiService.getTimelinePage = async function (data) {
  console.log("Get user timeline");
  const response = await interceptor.post(
    "ajax/users",
    new URLSearchParams({
      requestType: "timeline",
      pageNo: data.page,
      timeline: "topic",
      topicType: data.topicType ? data.topicType : 0,
      userId: data.userID,
      format: "json",
    })
  );
  console.log("Timeline data:", response);
  return response;
};

apiService.getConnectionRecommendations = async function (data) {
  console.log("Get connections reccomendations");
  const response = await interceptor.post(
    "ajax/users",
    new URLSearchParams({
      requestType: "connectionRecommendations",
      connID: data.connID ? data.connID : null,
      format: "json",
    })
  );
  console.log("Connection reccomendations data:", response);
  return response;
};

apiService.getOtherRecommendations = async function (data) {
  console.log("Get other reccomendations");
  const response = await interceptor.post(
    "ajax/users",
    new URLSearchParams({
      requestType: "otherRecommendations",
      location: data.location ? data.location : "Dallas",
      profession: data.profession ? data.profession : "Eng",
      format: "json",
    })
  );
  console.log("Other reccomendations data:", response);
  return response;
};

apiService.getTopicByID = async function (data) {
  console.log("Get topicInfo by ID");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicLoad",
      topicID: data.topicID,
      userID: data.userID,
      format: "json",
    })
  );
  console.log("Topics by ID data:", response);
  if (
    response.errorCat === 6 &&
    response.errorMessage.topicID === "Topic Not Authorized"
  ) {
    window.location.href = "/dashboard";
    toast("Topic Not Authorized", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.ERROR,
    });
  }
  return response;
};

apiService.getTopicsRecentShare = async function (data) {
  console.log("Get topic info recent share");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicList",
      // accessUserID: data.accessUserID,
      // ownerUserID: data.ownerUserID,
      searchType: data.searchType,
      pageNo: data.pageNo ? data.pageNo : 1,
      connID: data.ownerUserID,
      list: "mini",
      interaction: data.interaction ? data.interaction : 0,
      format: "json",
      topicType: data.topicType ? data.topicType : 0,
    })
  );
  console.log("Topics recent share data:", response);
  return response;
};

apiService.getTopicSharedList = async function (data) {
  console.log("Get topic shared list");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicSharedList",
      format: "json",
    })
  );
  console.log("Topic shared list data:", response);
  return response;
};

apiService.getTopicsProfile = async function (data) {
  console.log("Get topics profile share");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicList",
      ownerUserID: data.ownerUserID,
      searchType: data.searchType,
      pageNo: data.page ? data.page : 1,
      format: "json",
    })
  );
  console.log("Topics profile data:", response);
  return response;
};

apiService.createTopic = async function (data) {
  console.log("Create topic");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicUpdate",
      "topicData[action]": "add",
      "topicData[topicName]": data.topicName,
      "topicData[topicDescription]": data.topicName,
      "topicData[topicType]": data.topicType,
      "topicData[topicXData]": data.topicXData ? data.topicXData : "",
      "topicData[allowContribution]": data.allowContribution
        ? data.allowContribution
        : 0,
      userId: data.userID,
      format: "json",
    })
  );
  console.log("Created topic data:", response);
  return response;
};

apiService.deleteTopic = async function (data) {
  console.log("Delete topic");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicUpdate",
      "topicData[action]": "delete",
      "topicData[topicID]": data.topicID,
      format: "json",
    })
  );
  console.log("Deleted topic data:", response);
  if (!response.isError) {
    toast(response.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.SUCCESS,
    });
  } else {
    toast(response.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.ERROR,
    });
  }
  return response;
};

apiService.updateTopic = async function (data) {
  console.log("Update topic");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicUpdate",
      "topicData[action]": "update",
      "topicData[topicName]": data.topicName,
      "topicData[topicDescription]": data.topicDescription,
      "topicData[topicID]": data.topicID,
      "topicData[topicType]": data.topicType,
      format: "json",
    })
  );
  console.log("Updated topic data:", response);
  if (!response.isError) {
    toast(response.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.SUCCESS,
    });
  } else {
    toast(response.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.ERROR,
    });
  }
  return response;
};

apiService.likeNote = async function (data) {
  console.log("Note liked");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicNoteInteract",
      interactionType: "3",
      interaction: "1",
      noteID: data.noteID,
      topicId: data.topicID,
      format: "json",
    })
  );
  console.log("Note liked data:", response);
  return response;
};

apiService.unlikeNote = async function (data) {
  console.log("Note Unliked");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicNoteInteract",
      interactionType: "3",
      interaction: "0",
      // "noteData[noteID]": "",
      noteID: data.noteID,
      topicId: data.topicID,
      format: "json",
    })
  );
  console.log("Note unliked data:", response);
  return response;
};

apiService.topicNoteLikes = async function (data) {
  console.log("Topic note likes start");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicNoteLikes",
      noteID: data.noteID,
      format: "json",
    })
  );
  console.log("Topic note likes data:", response);
  return response;
};

apiService.addNoteTopic = async function (data) {
  console.log("Create topic note");
  let egoList = data.egoList;
  data = {
    requestType: "topicNoteUpdate",
    "noteData[action]": "add",
    "noteData[noteID]": "",
    "noteData[noteName]": "noteText",
    "noteData[topicID]": data.topicID,
    "noteData[noteText]": data.noteText,
    "noteData[noteType]": data.noteType ? data.noteType : "",
    "noteData[egoList][]": egoList,
    format: "json",
  };
  const response = await interceptor.post(
    "ajax/topics",
    queryString.stringify(data)
  );
  console.log("Created topic note:", response);
  if (!response.isError) {
    toast("Note Added", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.SUCCESS,
    });
  } else {
    toast(response.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.ERROR,
    });
  }
  return response;
};

apiService.deleteNoteTopic = async function (data) {
  console.log("Delete note topic");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicNoteUpdate",
      "noteData[action]": "delete",
      "noteData[noteID]": data.noteID,
      "noteData[topicID]": data.topicID,
      format: "json",
    })
  );
  console.log("Delete note topic:", response);
  if (!response.isError) {
    toast(response.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.SUCCESS,
    });
  } else {
    toast(response.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.ERROR,
    });
  }
  return response;
};

apiService.updateNoteTopic = async function (data) {
  console.log("Update note topic");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicNoteUpdate",
      "noteData[action]": "update",
      "noteData[noteID]": data.noteID,
      "noteData[noteName]": "noteText",
      "noteData[noteText]": data.noteText,
      "noteData[topicID]": data.topicID,
      format: "json",
    })
  );
  console.log("Update note topic:", response);
  if (!response.isError) {
    toast("Note updated", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.SUCCESS,
    });
  } else {
    toast(response.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.ERROR,
    });
  }
  return response;
};

apiService.topicNoteInteract = async function (data) {
  console.log("Interact topic note");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicNoteInteract",
      noteID: data.noteID,
      topicID: data.topicID ? data.topicID : "",
      interaction: 0, // only when interactionType 1 for thumbsup or 0 for thumbsdown
      comment: data.comment, // only when interactionType=2
      interactionType: data.interactionType, // 2 for comments and 3 for thumbs up/Down
      format: "json",
    })
  );
  console.log("Interact topic note:", response);
  return response;
};

apiService.addComment = async function (data) {
  console.log("Start Adding Comment");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicNoteInteract",
      noteID: data.noteID,
      interaction: 0, // only when interactionType 1 for thumbsup or 0 for thumbsdown
      comment: data.commentText, // only when interactionType=2
      interactionType: 2, // 2 for comments and 3 for thumbs up/Down
      format: "json",
    })
  );
  console.log("Finished Adding Comment:", response);
  if (!response.isError) {
    toast(response.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.SUCCESS,
    });
  } else {
    toast(response.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.ERROR,
    });
  }
  return response;
};

apiService.getTopicNotesByID = async function (data) {
  console.log("Get noteInfo by Topic ID");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicNoteLoad",
      noteID: data.noteID,
      format: "json",
    })
  );
  console.log("Notes by Topic ID data:", response);
  return response;
};

apiService.getTopicNoteList = async function (data) {
  console.log("Get topic note list by Topic ID");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicNoteList",
      topicID: data.topicID,
      pageNo: data.pageNo ? data.pageNo : 1,
      userID: data.userID,
      format: "json",
    })
  );
  console.log("Notes list by Topic ID data:", response);
  return response;
};

apiService.getPhotoComments = async function (data) {
  console.log("Get photo comments");
  const response = await interceptor.post(
    "ajax/objects",
    new URLSearchParams({
      requestType: "egoLoad",
      egoID: data.egoID,
      format: "json",
    })
  );
  console.log("Get photo comments data:", response);
  return response;
};

apiService.addPhotoComment = async function (data) {
  console.log("Add photo comment liked");
  const response = await interceptor.post(
    "ajax/objects",
    new URLSearchParams({
      requestType: "egoInteract",
      interactionType: "2", // 1 for liking // 2 Comments
      interaction: "0", // like | dislike // only when interactionType = 1; 1 for like or 0 for dislike
      egoID: data.egoID,
      comment: data.comment,
      format: "json",
    })
  );
  console.log("Add photo comment data:", response);
  if (!response.isError) {
    toast(response.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.SUCCESS,
    });
  } else {
    toast(response.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.ERROR,
    });
  }
  return response;
};

apiService.deletePhotoComment = async function (data) {
  console.log("Delete photo comment");
  console.log(data);
  const response = await interceptor.post(
    "ajax/objects",
    new URLSearchParams({
      requestType: "egoDeleteComment",
      commentID: data.commentID,
      egoID: data.egoID,
      format: "json",
    })
  );
  console.log("Delete photo comment data:", response);
  if (!response.isError) {
    toast("Comment Deleted", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.SUCCESS,
    });
  } else {
    toast(response.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.ERROR,
    });
  }
  return response;
};

apiService.likePhoto = async function (data) {
  console.log("Like photo");
  const response = await interceptor.post(
    "ajax/objects",
    new URLSearchParams({
      requestType: "egoInteract",
      interactionType: "1", // 1 for liking // 2 Comments
      interaction: "1", // like | dislike // only when interactionType = 1; 1 for like or 0 for dislike
      egoID: data.egoID,
      topicID: data.topicID,
      format: "json",
    })
  );
  console.log("Like photo data:", response);
  return response;
};

apiService.unlikePhoto = async function (data) {
  console.log("Unlike photo");
  const response = await interceptor.post(
    "ajax/objects",
    new URLSearchParams({
      requestType: "egoInteract",
      interactionType: "1", // 1 for liking // 2 Comments
      interaction: "0", // like | dislike // only when interactionType = 1; 1 for like or 0 for dislike
      egoID: data.egoID,
      topicID: data.topicID,
      format: "json",
    })
  );
  console.log("Like photo data:", response);
  return response;
};

apiService.deleteComment = async function (data) {
  console.log("Delete topic note comment");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicNoteDeleteComment",
      noteID: data.noteID,
      commentID: data.commentID,
      format: "json",
    })
  );
  console.log("Delete topic note comment data:", response);
  if (!response.isError) {
    toast(response.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.SUCCESS,
    });
  } else {
    toast(response.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.ERROR,
    });
  }
  return response;
};

apiService.updateTopicVisibility = async function (data) {
  console.log("Update topic permissions", data);
  let connections = data.connections;
  connections = [connections.join('","')];
  let cliques = data.cliques;
  cliques = [cliques.join('","')];
  let cliqueTypes = data.cliqueTypes;
  cliqueTypes = [cliqueTypes.join('","')];
  console.log("connections", connections);
  const response = await interceptor.post(
    "ajax/permissions",
    new URLSearchParams({
      uresourceID: data.uresourceID,
      resourceType: 6, // 6 = topic
      "permissionData[accessList][cliqueTypes][]": cliqueTypes,
      "permissionData[accessList][cliques][]": cliques,
      "permissionData[accessList][connections][]": connections,
      "permissionData[accessTypeID]": data.accessTypeID,
      requestType: "resourcePermUpdate",
      permissionID: data.permissionID,
      format: "json",
    })
  );
  console.log("Topic permissions data:", response);
  return response;
};

apiService.updateTopicVisibilityNew = async function (data) {
  let connections = data.connections;
  let cliques = data.cliques;
  let cliqueTypes = data.cliqueTypes;
  console.log("Update topic permissions", data);

  data = {
    uresourceID: data.uresourceID,
    resourceType: 6, // 6 = topic
    "permissionData[accessList][cliqueTypes][]":
      cliqueTypes.length >= 1 ? cliqueTypes : "",
    "permissionData[accessList][cliques][]": cliques.length >= 1 ? cliques : "",
    "permissionData[accessList][connections][]":
      connections.length >= 1 ? connections : "",
    "permissionData[accessTypeID]": data.accessTypeID,
    requestType: "resourcePermUpdate",
    permissionID: data.permissionID,
    format: "json",
  };

  const response = await interceptor.post(
    "ajax/permissions",
    queryString.stringify(data)
  );

  console.log("Topic permissions data:", response);
  return response;
};

apiService.getUploads = async function (data) {
  console.log("Get upload/containers");
  const response = await interceptor.post(
    "ajax/containers",
    new URLSearchParams({
      requestType: "containerList",
      catID: 1,
      pageNo: 1,
      format: "json",
    })
  );
  console.log("Uploads data:", response);
  return response;
};

apiService.getUploadDetails = async function (data) {
  console.log("Get upload/containers media objects", data);
  const response = await interceptor.post(
    "ajax/containers",
    new URLSearchParams({
      requestType: "containerDetails",
      containerID: data.containerID,
      format: "json",
    })
  );
  console.log("Uploads details data:", response);
  return response;
};

apiService.addMediaTopic = async function (data) {
  let photos = data.photos;
  photos = [photos.join('","')];
  console.log("Add media topic");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicPhotoUpdate",
      "photoData[action]": "add",
      "photoData[photoID][]": photos,
      "photoData[scale]": 1,
      "photoData[seqnum]": 1,
      "photoData[topicID]": data.topicID,
      format: "json",
    })
  );
  console.log("Add media topic data:", response);
  return response;
};

apiService.deleteMediaTopic = async function (data) {
  //let photos = data.photos;
  //photos = [photos.join('","')];
  console.log("Delete media topic");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicPhotoUpdate",
      "photoData[action]": "delete",
      "photoData[photoID]": data.photoID,
      "photoData[scale]": 1,
      "photoData[seqnum]": data.sequenceID,
      "photoData[topicID]": data.topicID,
      format: "json",
    })
  );
  console.log("Delete media topic data:", response);
  return response;
};

apiService.deleteMedia = async function (data) {
  console.log("Delete media");
  const response = await interceptor.post(
    "ajax/objects",
    new URLSearchParams({
      requestType: "egoDelete",
      egoID: data.egoID,
      format: "json",
    })
  );
  console.log("Delete media data:", response);
  return response;
};

apiService.getConnections = async function (data) {
  console.log("Get connections");
  const response = await interceptor.post(
    "ajax/circles",
    new URLSearchParams({
      requestType: "connList",
      resShared: data.resShared ? data.resShared : 1,
      pageNo: 1,
      format: "json",
    })
  );
  // console.log("Connections data:", response);
  return response;
};

apiService.getConnectionsProfile = async function (data) {
  console.log("Get connections profile");
  const response = await interceptor.post(
    "ajax/circles",
    new URLSearchParams({
      requestType: "connList",
      connID: data.connID,
      pageNo: data.page ? data.page : 1,
      format: "json",
    })
  );
  // console.log("Connections data:", response);
  return response;
};

apiService.getConnectionsRecentShareGroup = async function (data) {
  console.log("Get connections");
  const response = await interceptor.post(
    "ajax/circles",
    new URLSearchParams({
      requestType: "connList",
      resShared: 0,
      pageNo: 1,
      format: "json",
    })
  );
  console.log("Connections data:", response);
  return response;
};

apiService.removeConnection = async function (data) {
  console.log("Remove connections");
  const response = await interceptor.post(
    "ajax/circles",
    new URLSearchParams({
      requestType: "delConn",
      connID: data.connectionID,
      format: "json",
    })
  );
  console.log("Remove connections data:", response);
  return response;
};

apiService.getCircles = async function (data) {
  console.log("Get circles/groups");
  const response = await interceptor.post(
    "ajax/circles",
    new URLSearchParams({
      requestType: "circleList",
      format: "json",
    })
  );
  console.log("Groups data:", response);
  return response;
};

apiService.getTopicCircleList = async function (data) {
  console.log("Get circle topic list");
  const response = await interceptor.post(
    "ajax/topics",
    new URLSearchParams({
      requestType: "topicCircleList",
      circleID: data.circleID,
      userID: data.userID,
      pageNo: 1,
      format: "json",
    })
  );
  console.log("Circle topic list data:", response);
  return response;
};

apiService.getPermissions = async function (data) {
  console.log("Get permissions");
  const response = await interceptor.post(
    "ajax/permissions",
    new URLSearchParams({
      requestType: "resourcePermLoad",
      uresourceID: data.uresourceID,
      resourceType: data.resourceType,
      resourceID: data.resourceID,
      format: "json",
    })
  );
  console.log("Get permissions data:", response);
  return response;
};

apiService.getPermissionsProfile = async function (data) {
  console.log("Get permissions profile");
  const response = await interceptor.post(
    "ajax/permissions",
    new URLSearchParams({
      requestType: "resourcePermLoad",
      uresourceID: data.uresourceID,
      resourceType: 4,
      resourceID: data.uresourceID,
      format: "json",
    })
  );
  console.log("Get permissions data:", response);
  return response;
};

apiService.updateProfileVisibility = async function (data) {
  let connections = data.connections;
  let cliques = data.cliques;
  let cliqueTypes = data.cliqueTypes;
  console.log("Update profile permissions", data);

  data = {
    requestType: "resourcePermUpdate",
    permissionID: data.permissionID,
    uresourceID: data.uresourceID,
    resourceType: 4, // 3 = profile // 4 Conn list
    "permissionData[accessList][cliques][]": cliques.length >= 1 ? cliques : "",
    "permissionData[accessList][cliqueTypes][]":
      cliqueTypes.length >= 1 ? cliqueTypes : "",
    "permissionData[accessList][connections][]":
      connections.length >= 1 ? connections : "",
    "permissionData[accessTypeID]": data.accessTypeID, // 5 cliques
    format: "json",
  };

  const response = await interceptor.post(
    "ajax/permissions",
    queryString.stringify(data)
  );

  console.log("Update profile permissions data:", response);
  return response;
};

apiService.getAccessTypeLoad = async function (data) {
  console.log("Get access type");
  const response = await interceptor.post(
    "ajax/permissions",
    new URLSearchParams({
      requestType: "accesstypeLoad",
      resourceType: 6,
      format: "json",
    })
  );
  console.log("Get access type data:", response);
  return response;
};

apiService.getProfileByID = async function (data) {
  console.log("Get profile by ID");
  const response = await interceptor.post(
    "ajax/users",
    new URLSearchParams({
      requestType: "basicInfoLoad",
      userID: data.profileID,
      format: "json",
    })
  );
  console.log("Groups data:", response);
  return response;
};

apiService.updateProfile = async function (data) {
  console.log("Update user profile", data);
  const response = await interceptor.post(
    "ajax/users",
    new URLSearchParams({
      requestType: "accSettUpdate",
      "settings[dob][day]": data.day,
      "settings[dob][month]": data.month,
      "settings[dob][year]": data.year,
      "settings[email]": data.email,
      "settings[fname]": data.fname,
      "settings[lname]": data.lname,
      "settings[location]": data.location,
      "settings[motto]": data.motto ? data.motto : "",
      "settings[phoneNumber]": data.phoneNo,
      "settings[profession]": data.profession ? data.profession : "",
      "settings[showConnCount]": data.showConnCount,
      "settings[notifyConnReq]": data.notifyConnReq,
      format: "json",
    })
  );
  console.log("Update profile:", response);
  return response;
};

apiService.getEvents = async function (data) {
  console.log("Get events");
  const response = await interceptor.post(
    "ajax/users",
    new URLSearchParams({
      requestType: "eventList",
      ownerUserID: data.ownerUserID,
      pageNo: data.pageNo ? data.pageNo : 1,
      accessUserID: data.accessUserID,
      format: "json",
    })
  );
  console.log("Events data:", response);
  return response;
};

apiService.getConnRequests = async function (data) {
  console.log("Get connection requests");
  const response = await interceptor.post(
    "ajax/connections",
    new URLSearchParams({
      requestType: "connReqsLoad",
      format: "json",
    })
  );
  console.log("Connection request data:", response);
  return response;
};

apiService.acceptConnectionRequest = async function (data) {
  let cliques = data.cliques;
  console.log("Accept connection request");
  data = {
    requestType: "connReqUpdate",
    connReqID: data.connReqID,
    "cliqueIDs[]": cliques,
    connReqStatus: 1,
    format: "json",
  };
  const response = await interceptor.post(
    "ajax/connections",
    queryString.stringify(data)
  );

  console.log("Accept connection data:", response);
  return response;
};

apiService.addConnectionRequestOld = async function (data) {
  let cliques = data.cliques;
  cliques = [cliques.join('","')];
  console.log("Add connection request");
  const response = await interceptor.post(
    "ajax/connections",
    new URLSearchParams({
      requestType: "connReqAdd",
      connID: data.connID,
      "cliqueIDs[]": cliques,
      format: "json",
    })
  );
  console.log("Add connection data:", response);
  if (!response.isError) {
    toast(response.eventName, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.SUCCESS,
    });
  } else {
    toast(response.eventName, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.ERROR,
    });
  }
  return response;
};

apiService.addConnectionRequest = async function (data) {
  let cliques = data.cliques;
  console.log("Add connection request");
  data = {
    requestType: "connReqAdd",
    connID: data.connID,
    "cliqueIDs[]": cliques,
    format: "json",
  };
  const response = await interceptor.post(
    "ajax/connections",
    queryString.stringify(data)
  );
  console.log("Add connection data:", response);
  if (!response.isError) {
    toast(response.eventName, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.SUCCESS,
    });
  } else {
    toast(response.eventName, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: toast.TYPE.ERROR,
    });
  }
  return response;
};

apiService.declineConnectionRequest = async function (data) {
  console.log("Decline/ignore connection request");
  const response = await interceptor.post(
    "ajax/connections",
    new URLSearchParams({
      requestType: "connReqUpdate",
      connReqID: data.connReqID,
      "cliqueIDs[]": "",
      connReqStatus: 2,
      format: "json",
    })
  );
  console.log("Decline connection data:", response);
  return response;
};

apiService.blockConnection = async function (data) {
  console.log("Block connection");
  const response = await interceptor.post(
    "ajax/users",
    new URLSearchParams({
      requestType: "addUserBlock",
      onUserID: data.onUserID, // user ID to block
      fromUserID: data.fromUserID, // user ID requesting block
      reason: "I want to report this user",
      reportUserID: data.reportUserID, // user ID requesting report
      format: "json",
    })
  );
  console.log("Block connections data:", response);
  return response;
};

apiService.ignoreSuggestion = async function (data) {
  console.log("Ignore connection suggest");
  const response = await interceptor.post(
    "ajax/users",
    new URLSearchParams({
      requestType: "addRecommendIgnore",
      userIDToIgnore: data.userID,
      format: "json",
    })
  );
  console.log("Ignore data:", response);
  return response;
};

apiService.getGroupByID = async function (data) {
  console.log("Get group ID");
  const response = await interceptor.post(
    "ajax/circles",
    new URLSearchParams({
      requestType: "circleConnList",
      circleID: data,
      pageNo: 1,
      format: "json",
    })
  );
  console.log("Group by ID data:", response);
  return response;
};

apiService.createGroup = async function (data) {
  console.log("Create group");
  const response = await interceptor.post(
    "ajax/circles",
    new URLSearchParams({
      requestType: "circleAdd",
      circleTypeID: data.circleTypeID, //1 Family //2 Friends //3 Colleague
      circleName: data.circleName,
      format: "json",
    })
  );
  console.log("Create group:", response);
  return response;
};

apiService.updateGroup = async function (data) {
  console.log("Update group");
  const response = await interceptor.post(
    "ajax/circles",
    new URLSearchParams({
      requestType: "circleUpdate",
      circleID: data.circleTypeID,
      circleName: data.circleName,
      format: "json",
    })
  );
  console.log("Update group data:", response);
  return response;
};

apiService.deleteGroup = async function (data) {
  console.log("Delete group");
  const response = await interceptor.post(
    "ajax/circles",
    new URLSearchParams({
      requestType: "delCircle",
      circleID: data.circleID,
      format: "json",
    })
  );
  console.log("Delete group data:", response);
  return response;
};

apiService.connectionCircleUpdate = async function (data) {
  console.log("Update connection circle", data);
  const response = await interceptor.post(
    "ajax/circles",
    new URLSearchParams({
      requestType: "connCircleUpdate",
      connID: data.connID,
      "circleAssignment[0][actionType]": data.actionType, //type 1 add //type 2 delete
      "circleAssignment[0][circleID]": data.circleID,
      format: "json",
    })
  );
  console.log("Update connection circle data:", response);
  return response;
};

apiService.connectionCircleList = async function (data) {
  console.log("Get connection circle list", data);
  const response = await interceptor.post(
    "ajax/circles",
    new URLSearchParams({
      requestType: "connCircleList",
      connID: data.connID,
      format: "json",
    })
  );
  console.log("Connection circle list data:", response);
  return response;
};

apiService.connectionResourceList = async function (data) {
  console.log("Get connection resource list", data);
  const response = await interceptor.post(
    "ajax/connections",
    new URLSearchParams({
      requestType: "connResourceList",
      format: "json",
    })
  );
  console.log("Connection resource list data:", response);
  return response;
};

apiService.searchUsers = async function (data) {
  console.log("Search users");
  const response = await interceptor.post(
    "search/search",
    new URLSearchParams({
      requestType: "userSearch",
      query: data.query,
      queryType: 1,
      pageNo: data.pageNo ? data.pageNo : 1,
      format: "json",
    })
  );
  console.log("Search users data:", response);
  return response;
};

apiService.searchTopics = async function (data) {
  console.log("Search topics");
  const response = await interceptor.post(
    "search/search",
    new URLSearchParams({
      requestType: "topicSearch",
      query: data.query,
      pageNo: data.pageNo ? data.pageNo : 1,
      format: "json",
    })
  );
  console.log("Search topics data:", response);
  return response;
};

apiService.searchContainers = async function (data) {
  console.log("Search containers");
  const response = await interceptor.post(
    "search/search",
    new URLSearchParams({
      requestType: "containerSearch",
      query: data.query,
      pageNo: data.pageNo ? data.pageNo : 1,
      format: "json",
    })
  );
  console.log("Search containers data:", response);
  return response;
};

apiService.checkContacts = async function (data) {
  console.log("Check contacts");
  const response = await interceptor.post(
    "ajax/users",
    new URLSearchParams({
      requestType: "checkContact",
      matchList: data.matchList,
      format: "json",
    })
  );
  console.log("Check contacts data:", response);
  return response;
};

apiService.userOnlineStatus = async function (data) {
  console.log("User online status");
  const response = await interceptor.post(
    "ajax/users",
    new URLSearchParams({
      requestType: "userOnlineStatus",
      status: data.status,
      format: "json",
    })
  );
  console.log("User online status data:", response);
  return response;
};

apiService.setUserStatus = async function (data) {
  console.log("Set user status");
  const response = await interceptor.post(
    "ajax/users",
    new URLSearchParams({
      requestType: "setUserStatus",
      userStatus: data.userStatus,
      format: "json",
    })
  );
  console.log("Set user status data:", response);
  return response;
};

apiService.inviteContacts = async function (data) {
  console.log("Invite contacts");
  let cliques = data.cliques;
  data = {
    requestType: "connInvite",
    "cliqueIDs[]": cliques,
    invitePhone: data.invitePhone,
    inviteEmail: data.inviteEmail,
    format: "json",
  };
  const response = await interceptor.post(
    "ajax/connections",
    queryString.stringify(data)
  );
  console.log("Invite contacts data:", response);
  return response;
};

apiService.deactivateAccount = async function (data) {
  console.log("Deactivate account");
  const response = await interceptor.post(
    "ajax/users",
    new URLSearchParams({
      requestType: "setAccountStatus",
      userID: data.userID,
      accountStatus: 0,
      format: "json",
    })
  );
  console.log("Deactivate account data", response);
  return response;
};

apiService.deleteAccount = async function (data) {
  console.log("Delete account");
  const response = await interceptor.post(
    "ajax/users",
    new URLSearchParams({
      requestType: "setAccountStatus",
      userID: data.userID,
      accountStatus: 2,
      format: "json",
    })
  );
  console.log("Delete account data", response);
  return response;
};

apiService.logout = async function (data) {
  console.log("Logout EW API");
  const response = await interceptor.post(
    "ajax/logout",
    new URLSearchParams({
      format: "json",
    })
  );
  console.log("Logged out", response);
  return response;
};

export default apiService;
